import React from 'react'
import './header.css'

import ProfileImage from '../../assets/profileImage.png'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Welcome!</h5>
        <h1>Harry Tozer</h1>
        <h5 className="text-light">Graphic Designer</h5>

        <div className="ProfileImage">
          <img src={ProfileImage} alt="ProfileImage"/>
        </div>

        
        <div className='scroll-down'></div>
      </div>
    </header>
    
  )
}

export default Header