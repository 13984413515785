import React from 'react'
import './about.css'

import { Parallax } from 'react-scroll-parallax';

import {FaGraduationCap} from 'react-icons/fa'
import {IoPeopleCircleSharp} from 'react-icons/io5'


const About = () => {
  return (
    <section id="about">

        <h2>About Me!</h2>

        <div className="container about__container">

          <div className="about__blurb">
            <Parallax 
            translateY={['-125px', '-250px']}
            opacity={[0.3, 1]}
            easing="easeInQuad">

              <div className='About__Hi'>Hi!</div>
            </Parallax>
            <p>Im Harry, a graphic designer who grew up in the Blue Mountains, Australia. I have always gravitated towards the creative and practical, be it working in the workshop, throughout my schooling or simply playing with any software I could get my hands on! This is what drove me to study design at the University of New South Wales, to further my understanding and pursue a creative career in graphics.</p>
          </div>

          <div className="about__icons">

                <FaGraduationCap className="about__icon" />
                <h4>Qualifications</h4><br />
                <p>Bachelors of Design</p>
                <small className='smaller'>Awarded with Distiction <br />University of New South Wales</small>

          </div>

        </div>
    </section>
  )
}

export default About