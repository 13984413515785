import React, {useState, useEffect} from 'react'
import './parallax.css'

import parallax1 from '../../assets/parallax/parallax1.png'
import parallax2 from '../../assets/parallax/parallax2.png'
import parallax3 from '../../assets/parallax/parallax3.png'
import parallax4 from '../../assets/parallax/parallax4.png'
import parallax5 from '../../assets/parallax/parallax5.png'


const Parallax = () => {

    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className='parallax'>

            <div className='parallax__object' style={{ transform: `translateY(+${offsetY * 0.6 }px)` }}>
                <img className='parallax__img' src={parallax5} />
            </div>

            <div className='parallax__object' style={{ transform: `translateY(+${offsetY * 0.4 }px)` }}>
                <img className='parallax__img' src={parallax4} />
            </div>

            <div className='parallax__object' style={{ transform: `translateY(+${offsetY * 0.2 }px)` }}>
                <img className='parallax__img' src={parallax3} />
            </div>

            <div className='parallax__object' style={{ transform: `translateY(+${offsetY * 0.1 }px)` }}>
                <img className='parallax__img' src={parallax2} />
            </div>


        </div>
      
    )
  }
  
  export default Parallax