import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer>
    <div className='footer__top'>
      <h3>Harry Tozer</h3>
    </div>

    <div className="footer__bottom">
        <h5>© Harry Tozer 2022</h5>
    </div>
    </footer>
  )
}

export default Footer