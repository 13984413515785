import './App.css';

import { ParallaxProvider } from 'react-scroll-parallax';

import Portfolio from './components/portfolio/Portfolio';
import Parallax from './components/parallax/Parallax';
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Projects from './components/projects/Projects'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'

import FourOhFour from './components/pages/fof/Fof'
import Fullportfolio from './components/pages/fullportfolio/Fullportfolio'

import Proscenium from './components/pages/proscenium/Proscenium'
import Wayfinding from './components/pages/wayfinding/Wayfinding'
import CWL from './components/pages/cwl/Cwl'
import PaddleAus from './components/pages/paddleaus/Paddleaus'
import Vivid from './components/pages/vivid/Vivid'
import Newington from './components/pages/newington/Newington'


import { Routes, Route} from 'react-router-dom';

function App() {
  return (
    <ParallaxProvider>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/portfolio" element={<Fullportfolio />} />

        <Route path="/wayfinding" element={<Wayfinding />} />
        <Route path="/proscenium" element={<Proscenium />} />
        <Route path="/cwl" element={<CWL />} />
        <Route path="/paddleaus" element={<PaddleAus />} />
        <Route path="/vivid" element={<Vivid />}/>
        <Route path="/newington" element={<Newington />}/>

        <Route path='*' element={<FourOhFour />}/>

      </Routes>
    </ParallaxProvider>
  );
}

export default App;


function Home() {
  return (
    <>
        <Parallax />
        <Header />
        <About />
        <Portfolio />
        <Projects />
        <Contact />
        <Footer />
    </>
  );
}