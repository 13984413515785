import React from 'react'
import './fullportfolio.css'

import ProsceniumShowcase from '../../../assets/showcase/ProsceniumShowcase.jpg'
import OperaVividShowcase from '../../../assets/showcase/VividOperaShowcase.jpg'
import CWLShowcase from '../../../assets/showcase/CWLShowcase.jpg'
import PAShowcase from '../../../assets/showcase/PAShowcase.jpg'
import UNSWShowcase from '../../../assets/showcase/UNSWShowcase.jpg'
import NewingtonShowcase from '../../../assets/showcase/NewingtonShowcase.jpg'

import { Link } from 'react-router-dom'

const Fullportfolio = () => {
  return (
    <div className="grid image-grid">

      <div className="grid-block">
        
        <div className="tile">
          
          <div className="tile-hidden">
              <h1 className='tile-text'> Proscenium </h1>
          </div>

          <Link to="/proscenium" className="tile-link">
            <img className="tile-img tile-shadow" src={ProsceniumShowcase} alt="Image"/>
          </Link>

        </div>
      </div>

      <div className="grid-block">
        <div className="tile">

          <div className="tile-hidden">
              <h1 className='tile-text'> Mock Vivid Display </h1>
          </div>

          <Link to="/vivid" className="tile-link">
            <img className="tile-img tile-shadow" src={OperaVividShowcase} alt="Image"/>
          </Link>
        </div>
      </div>
      

      <div className="grid-block">
        
        <div className="tile">
          
          <div className="tile-hidden">
              <h1 className='tile-text'> Back To Newington Day </h1>
          </div>

          <Link to="/newington" className="tile-link">
            <img className="tile-img tile-shadow" src={NewingtonShowcase} alt="Image"/>
          </Link>

        </div>
      </div>
      
        <div className="grid-block">
          <div className="tile">

            <div className="tile-hidden">
                <h1 className='tile-text'>Paddle Australia Helmet Designs</h1>
            </div>

            <Link to="/paddleaus" className="tile-link">
              <img className="tile-img tile-shadow" src={PAShowcase} alt="Image"/>
            </Link>
            
          </div>
        </div>

      <div className="grid-block">
        <div className="tile">

          
            <div className="tile-hidden">
                <h1 className='tile-text'>UNSW Paddington Wayfinding system</h1>
            </div>

            <Link to="/wayfinding" className="tile-link">
              <img className="tile-img tile-shadow" src={UNSWShowcase} alt="Image"/>
            </Link>
        </div>
      </div>

      <div className="grid-block">
        <div className="tile">

          <div className="tile-hidden">
            <h1 className='tile-text'>Creating with Light</h1>
          </div>

          <Link to="/cwl" className="tile-link">
            <img className="tile-img tile-shadow" src={CWLShowcase} alt="Image"/>
          </Link>
        </div>
      </div>
      
    </div>
  )
}

export default Fullportfolio