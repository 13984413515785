import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineContacts} from 'react-icons/ai'
import {BsImages} from 'react-icons/bs'
import {IoReturnUpBack} from "react-icons/io5"
import {useState} from 'react'

import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';

const Nav = () => {
  const location = useLocation()
  const Navigate = useNavigate()
  const [activeNav, setActiveNav] = useState('#')

  var ShowBackButton = false;

  if (location.pathname != "/" && location.pathname != "/portfolio"){
    ShowBackButton = true;
  }

  const goToHome = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    setActiveNav('#');
  };

  const goToAbout = () => {

    var Yposition = document.getElementById("about").offsetTop;
    window.scrollTo({
        top: Yposition - 140,
        behavior: "smooth",
    });
  };

  const goToPortfolio = () => {

    var Yposition = document.getElementById("portfolio").offsetTop;
    window.scrollTo({
        top: Yposition - 140,
        behavior: "smooth",
    });
  };

  const goToProjects = () => {

    var Yposition = document.getElementById("projects").offsetTop;
    window.scrollTo({
        top: Yposition - 140,
        behavior: "smooth",
    });
  };

  const goToContact = () => {

    var Yposition = document.getElementById("contact").offsetTop;
    window.scrollTo({
        top: Yposition - 140,
        behavior: "smooth",
    });
  };


  return (
      <>
      {ShowBackButton ?
      <Link to="/portfolio">
        <div className='Nav__Backbtn'>
          <div className='Nav_BackIcon'> <IoReturnUpBack size={42}/> </div>
        </div>
      </Link>
      :       
      <nav>
        <NavLink to="/portfolio"><BsImages/></NavLink>
        
        <Link to="/" onClick={() => goToHome()} className={activeNav === '#' ? 'active home' : 'home'}><AiOutlineHome/></Link>
        
        <Link to="/" onClick={() => goToContact()} className={activeNav === '#contact' ? 'active' : ''}><AiOutlineContacts/></Link>
      </nav>}
      </>
  )
}

export default Nav

{/* <Link to="/" onClick={() => goToProjects()} className={activeNav === '#projects' ? 'active' : ''}><VscProject/></Link>
<Link to="/" onClick={() => goToAbout()} className={activeNav === '#about' ? 'active' : ''}><AiOutlineInfoCircle/></Link> */}

