import React from 'react'
import './wayfinding.css'

import { Link } from 'react-router-dom'
import Wayfinding0 from '../../../assets/projects/wayfinding/wayfinding.jpg'
import Wayfinding1 from '../../../assets/projects/wayfinding/wayfinding1.jpg'
import Wayfinding2 from '../../../assets/projects/wayfinding/wayfinding2.jpg'
import Wayfinding3 from '../../../assets/projects/wayfinding/wayfinding3.jpg'
import Wayfinding4 from '../../../assets/projects/wayfinding/wayfinding4.jpg'
import Wayfinding5 from '../../../assets/projects/wayfinding/wayfinding5.jpg'
import Wayfinding6 from '../../../assets/projects/wayfinding/wayfinding6.jpg'
import Wayfinding7 from '../../../assets/projects/wayfinding/wayfinding7.jpg'

const Wayfinding = () => {
  return (
    <section id="wayfinding">
      <div className='wayfinding__header'>
        <div className='wayfinding__heading'><h2>UNSW Paddington Wayfinding system</h2></div>
        <div className='wayfinding_subtext'>
        <p>For this project, I was given the task of developing a wayfinding system that both embodies the creativity and spirit of the Paddington campus and also is clear and easy to follow. For the design I decided to develop and model, I chose to focus on the building blocks and creative foundation that the Paddington campus instils in its students.</p>
        </div>

      </div>

      <div className='wayfinding__images'>
        <img className="wayfinding__image" src={Wayfinding0} />
        <img className="wayfinding__image" src={Wayfinding1} />
        <img className="wayfinding__image" src={Wayfinding2} />
        <img className="wayfinding__image" src={Wayfinding3} />
        <img className="wayfinding__image" src={Wayfinding4} />
        <img className="wayfinding__image" src={Wayfinding5} />
        <img className="wayfinding__image" src={Wayfinding6} />
        <img className="wayfinding__image" src={Wayfinding7} />
      </div>
    </section>
  )
}

export default Wayfinding