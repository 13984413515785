import React from 'react'
import './projects.css'

import {FaGraduationCap} from 'react-icons/fa'
import {IoPeopleCircleSharp} from 'react-icons/io5'


const Projects = () => {
  return (
    <section id="projects">
      <h2>Other Projects!</h2>
        <div className="container projects__container">

          <div className="projects__blurb">
            <p>As a designer I am constantly looking for new ways to create and develop projects, this can often extend outside the realms of graphics! These are some of the alternative projects I have worked on.</p>
            <p>These projects are typically born from a problem that I can't find an obvious(or ideal) solution to! I often find myself learning a new method to overcome and solve these where possible.</p>
          </div>

          <div className="projects__icons">
            <article className="projects__card">
              <FaGraduationCap className="projects__icon" />
              <h5>Proscenium</h5>
              <small>Monitor Brightness and backlight Management tool</small><br/>
              <small className='smaller'>Let backlight bleed and irritating light pollution from secondary monitors be thing of the past!</small>
            </article>
          </div>

        </div>
    </section>
  )
}

export default Projects