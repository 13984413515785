import React from 'react'
import './paddleaus.css'

import PaddleAus1 from '../../../assets/projects/paddleaus/PaddleAus1.jpg'
import PaddleAus2 from '../../../assets/projects/paddleaus/PaddleAus2.jpg'
import PaddleAus3 from '../../../assets/projects/paddleaus/PaddleAus3.jpg'
import PaddleAus4 from '../../../assets/projects/paddleaus/PaddleAus4.jpg'
import PaddleAus5 from '../../../assets/projects/paddleaus/PaddleAus5.jpg'
import PaddleAus6 from '../../../assets/projects/paddleaus/PaddleAus6.jpg'

const Paddleaus = () => {
  return (
    <section id="paddleaus">
      <div className='paddleaus__header'>
        <div className='paddleaus__heading'><h2>Paddle Australia Helmet Designs</h2></div>
        <div className='paddleaus_subtext'>
        <p>Working in conjuction with HelloBec graphic design, I developed a range of paddle helmet designs and mockups for the Australian athletes. 
          These designs were working within constraints of the material size and Paddle Australias desired colours (green and gold). 
          The designs themselves also needed to be something the athletes were proud to wear when representing their country!</p>
        </div>

      </div>
      
      <div className='paddleaus__images'>
        <div className='paddleaus_container'>
          <div className='paddleaus_column'>
            <img className="paddleaus__image" src={PaddleAus1} />
          </div>

          <div className='paddleaus_column'>
            <img className="paddleaus__image" src={PaddleAus2} />
          </div>
        </div>

        <div className='paddleaus_container'>
          <div className='paddleaus_column'>
            <img className="paddleaus__image" src={PaddleAus3} />
          </div>

          <div className='paddleaus_column'>
            <img className="paddleaus__image" src={PaddleAus4} />
          </div>
        </div>

        <div className='paddleaus_container'>
          <div className='paddleaus_column'>
            <img className="paddleaus__image" src={PaddleAus5} />
          </div>

          <div className='paddleaus_column'>
            <img className="paddleaus__image" src={PaddleAus6} />
          </div>
        </div>


      </div>
    </section>
  )
}

export default Paddleaus