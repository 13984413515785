import React from 'react'
import './contact.css'

const Contact = () => {
  return (
    <section id="contact">
      
    <h2>Get in Contact!</h2> 


    </section>
  )
}

export default Contact