import React from 'react'
import './fof.css'

import { Link } from 'react-router-dom'

const FourOhFour = () => {
  return (
    <section id="fof">
      <div className='fof__div'>
        <h2 className='fof__ohno'>Oh no!</h2> 
        <h1 className='fof__text'>How did you get here?</h1>
        <Link to="/">
          <button className="fof__Button">Back to safety</button>
        </Link>
      </div>
    </section>
  )
}

export default FourOhFour