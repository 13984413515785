import React from 'react'
import './vivid.css'

import { Link } from 'react-router-dom'
import VividOriginal from '../../../assets/projects/vivid/VividOriginal.jpg'
import VividFull from '../../../assets/projects/vivid/VividDisplay.jpg'

const Vivid = () => {
  return (
    <section id="vivid">
      <div className='vivid__header'>
        <div className='vivid__heading'><h2>Mock Vivid Display</h2></div>
        <div className='vivid_subtext'>
        <p>For this project, I was tasked with turning a bright, bustling photograph of the Sydney Opera house into a night time mock Vivid Display using nothing but the supplied original photograph and assets for the projection. This was a challenge I was very excited to overcome, to showcase my photoshop ability, breaking down this image into its many layers and intricacies.</p>
        </div>
      </div>

      <div className='vivid__images'>
        <img className="vivid__image" src={VividOriginal} />
        <img className="vivid__image" src={VividFull} />
      </div>
    </section>
  )
}

export default Vivid