import React from 'react'
import './portfolio.css'

import Slider from "react-slick"

import ProsceniumShowcase from '../../assets/showcase/ProsceniumShowcase.jpg'
import OperaVividShowcase from '../../assets/showcase/VividOperaShowcase.jpg'
import CWLShowcase from '../../assets/showcase/CWLShowcase.jpg'
import PAShowcase from '../../assets/showcase/PAShowcase.jpg'
import UNSWShowcase from '../../assets/showcase/UNSWShowcase.jpg'

import { Link } from 'react-router-dom';


const Portfolio = () => {
  
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);


  var SlideAmount = 3;
  if(window.innerWidth <= 1400){
    SlideAmount = 1;
  }

  const settings = {
    className: "Showcase__Card",
    centerMode: true,
    infinite: true,
    centerPadding: "20px",
    slidesToShow: SlideAmount,
    speed: 500,
    focusOnSelect: true,
    arrows: false,
    dots: true
  };

  return (
    <section id="portfolio" className="Portfolio__container">
      <h2 className="Portfolio__Header">My Work</h2>
      
      <h5>Check out some of my work!</h5>
      <div className="Portfolio__ButtonDiv">
        <Link to="/portfolio">
          <button className="Portfolio__Button">View full portfolio</button>
        </Link>
      </div>

      <div className="Showcase">
        <Slider {...settings}>
            <div>
              <div className='Showcase__Content'>
                <img className="Showcase__img" src={OperaVividShowcase} alt="OperaVivid"/>

                <div className='Showcase__hidden'>
                  <div className='Showcase__text'>
                    <h2>Opera House Vivid Display</h2>
                    <p>Mock vivid display with day / night conversion.</p>

                    <Link to="../vivid">
                      <button className="Showcase__Button">View Project</button>
                    </Link>
                  </div>
                </div>

              </div>
            </div>

            <div>
              <div className='Showcase__Content'>
                <img className="Showcase__img" src={UNSWShowcase} alt="UNSWShowcase"/>
                <div className='Showcase__hidden'>
                  <div className='Showcase__text'>
                    <h2>UNSW Paddington Wayfinding system</h2>
                    <p>Concept wayfinding system for the UNSW Paddington campus.</p>

                    <Link to="../wayfinding">
                      <button className="Showcase__Button">View Project</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='Showcase__Content'>
                <img className="Showcase__img" src={ProsceniumShowcase} alt="Proscenium"/>

                <div className='Showcase__hidden'>
                  <div className='Showcase__text'>
                    <h2>Proscenium</h2>
                    <p>Logo development for monitor management tool.</p>

                    <Link to="../proscenium">
                      <button className="Showcase__Button">View Project</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className='Showcase__Content'>
                <img className="Showcase__img" src={CWLShowcase} alt="CWLShowcase"/>

                <div className='Showcase__hidden'>
                  <div className='Showcase__text'>
                    <h2>Creating with Light</h2>
                    <p>Logo development for Creating with Light</p>

                    <Link to="../cwl">
                      <button className="Showcase__Button">View Project</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
            <div className='Showcase__Content'>
                <img className="Showcase__img" src={PAShowcase} alt="PAShowcase"/>

                <div className='Showcase__hidden'>
                  <div className='Showcase__text'>
                    <h2>Paddle Australia Helmet Designs</h2>
                    <p>Helmet designs and mockup for Paddle Australia.</p>

                    <Link to="../paddleaus">
                    <button className="Showcase__Button">View Project</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </Slider>
      </div>
    </section>
  )
}

export default Portfolio