import React from 'react'
import './newington.css'

import { Link } from 'react-router-dom'
import Mapstall from '../../../assets/projects/newington/Mapstall.jpg'
import SportsProgram from '../../../assets/projects/newington/SportsProgram.jpg'
import Directional from '../../../assets/projects/newington/Directional.jpg'
import BusSign from '../../../assets/projects/newington/BusSign.jpg'
import Cashless from '../../../assets/projects/newington/Cashless.jpg'
import EntryConds from '../../../assets/projects/newington/EntryConditions.jpg'
import Stall from '../../../assets/projects/newington/Stall.jpg'
import Map from '../../../assets/projects/newington/Map.jpg'

import Photobooth1 from '../../../assets/projects/newington/PhotoBooth1.png'
import Photobooth2 from '../../../assets/projects/newington/PhotoBooth2.png'



const CWL = () => {
  return (
    <section id="new">
      <div className='new__header'>
        <div className='new__heading'><h2>Back To Newington Day</h2></div>
        <div className='new_subtext'>
        <p>I worked on the creative roll out across signage and wayfinding for Back to Newington Day at Newington College. 
          BTND is a large scale school event that draws thousands of attendees each year, including students, alumni and visitors. The day featured sporting events, vendor stalls, alumni lunches, a marketplace and much more. 
          The event itself required a large scale collateral, wayfinding and vendor signage rollout. Some of the work created included: menus, seating / table cards, transport signage, directional signage, vendor menus / pricing / terms,
          promotional assets, leaflets, entry signage and lanyards.</p>
        </div>

      </div>

      <div className='new__images'>
        <img className="new__image" src={Mapstall} />
        <img className="new__image" src={SportsProgram} />
        <img className="new__image" src={Directional} />

        <div className='new_container'>

          <div className='new_column'>
            <img className="new__image" src={BusSign} />
          </div>

          <div className='new_column'>
            <img className="new__image" src={Cashless} />
          </div>
        </div>

        <img className="new__image" src={Map} />

        <div className='new_container'>

          <div className='new_column'>
            <img className="new__image" src={Photobooth1} />
          </div>

          <div className='new_column'>
            <img className="new__image" src={Photobooth2} />
          </div>
        </div>

        <img className="new__image" src={Stall} />

      </div>
    </section>
  )
}

export default CWL