import React from 'react'
import './cwl.css'

import { Link } from 'react-router-dom'
import CWLLettering from '../../../assets/projects/cwl/CWLLettering.jpg'
import CWLvariant2 from '../../../assets/projects/cwl/CWLvariant2.jpg'
import CWLvariant3 from '../../../assets/projects/cwl/CWLvariant3.jpg'


const CWL = () => {
  return (
    <section id="cwl">
      <div className='cwl__header'>
        <div className='cwl__heading'><h2>Creating with Light</h2></div>
        <div className='cwl_subtext'>
        <p> Creating with Light is an alternative healing company that offers a combination of contemporary and traditional healing modalities to treat clients. 
          Treatment ranges from physical to emotional and spiritual based sessions. Connor, the founder of Creating with Light, commissioned me to develop a 
          logo which embodied the spiritual and positive nature of his practice.</p>
        </div>

      </div>
      <div className='cwl__images'>
        <img className="cwl__image" src={CWLLettering} />

        <div className='cwl_container'>

          <div className='cwl_column'>
            <img className="cwl__image" src={CWLvariant2} />
          </div>

          <div className='cwl_column'>
            <img className="cwl__image" src={CWLvariant3} />
          </div>
        </div>

      </div>
    </section>
  )
}

export default CWL