import React from 'react'
import './proscenium.css'

import { Link } from 'react-router-dom'

import ProsceniumWhite from '../../../assets/projects/proscenium/ProsceniumShowcase.jpg'
import ProsceniumLoading from '../../../assets/projects/proscenium/ProsceniumLoading.gif'
import Prosceniumfull from '../../../assets/projects/proscenium/HeaderLogo.png'
import ProsceniumSS from '../../../assets/projects/proscenium/ProsceniumSS1.png'
import ProsceniumSS2 from '../../../assets/projects/proscenium/ProsceniumSS2.png'

const Proscenium = () => {
  return (
    <section id="proscenium">
      <div className='proscenium__header'>
        <div className='proscenium__heading'><h2>Proscenium Monitor Management</h2></div>
        <div className='proscenium_subtext'>
        <p>Proscenium is a program I developed to combat the effects of backlight bleed and light pollution from secondary monitors. The program is built in Electron with a native javascript backend and html/js frontend and utilises a number of C++ dlls to access native monitor control ie: dimming and backlight. The logo development and visual style for this project was influenced by the cinema, theatre curtains in particular.</p>
        </div>

      </div>

      <div className='proscenium__images'>
        <img className="proscenium__image" src={Prosceniumfull} />
        
        <div className='proscenium_container'>

          <div className='proscenium_column'>
            <img className="proscenium__image" src={ProsceniumWhite} />
          </div>

          <div className='proscenium_column'>
            <img className="proscenium__image" src={ProsceniumLoading} />
          </div>

        </div>
        <img className="proscenium__image" src={ProsceniumSS} />
        <img className="proscenium__image" src={ProsceniumSS2} />

      </div>
    </section>
  )
}

export default Proscenium